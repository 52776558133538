"use client";

import NotFoundActions from "@components/not-found-actions";
import Image from "next/image";
import img from "../images/404.png";

export default function NotFound() {
  return (
    <div className="bg-white h-[100vh] flex flex-col items-center justify-center px-[11px]">
      <Image src={img} width={600} height={600} alt="404" priority className="max-h-[80%]" />
      <NotFoundActions />
    </div>
  );
}
