"use client";

import { Button } from "@brainfinance/icash-component-library";

export default function NotFoundActions() {
  return (
    <div>
      <p className="text-[17px] text-gray-300 leading-[25px] tracking-[-0.3px] text-center max-w-[660px]">
        We could not find this page. Check that you typed the address correctly or{" "}
        <span
          className="text-primary cursor-pointer !font-600"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          click here
        </span>{" "}
        to open iCash principal page to view products we offer to get you back on track to financial health anywhere, at
        any time.
      </p>
      <div className="mt-[16px] flex justify-center">
        <Button
          appearance="primary"
          onClick={() => {
            window.location.href = "/dashboard";
          }}
        >
          Go to dashboard
        </Button>
      </div>
    </div>
  );
}
